<template>
    <div>
        <VFormRender
            v-if="queryformData"
            class="query-form-data"
            ref="queryform"
            :formJson="queryformData"
        ></VFormRender>

        <el-form  v-if="queryformData">
            
            <!-- label-width="68px" -->
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>
        <jf-table
            ref="jftable"
            :tree-config="treeConfig"
            :loading="loading"
            :query-params="queryParams"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @sort-change="sortChange"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
            @toggle-tree-expand="toggleExpandChangeEvent"
            @cell-dblclick="Oncelldblclick"
            @page-change="handleSizeChange"
            :total="total"
            :filterBtn="switchshowbtn('filter')"
            :sortBtn="switchshowbtn('sort')"
            :operate="false"
            @change="tableChange"
            :max-height="'450px'"
        >
            <template slot="status" slot-scope="{ row }">
                <el-switch
                    v-model="row.status"
                    active-value="1"
                    inactive-value="0"
                    @change="handleStatusChange(row)"
                ></el-switch>
            </template>
            <template slot="roles" slot-scope="{ row }">
                <span v-for="(item, index) of row.roles" :key="index">
                    {{ item.name }}
                </span>
            </template>
            <template
                :slot="item.slots.default"
                slot-scope="{ row }"
                v-for="item in columnSlots"
            >
                <div v-html="getFun(item, row)"></div>
            </template>
        </jf-table>
    </div>
</template>
<script>
import { customFields } from "@/vform/components/form-designer/widget-panel/widgetsConfig.js";
import { loadExtension } from "@/vform/extension/extension-loader";
import VFormRender from "@/vform/components/form-render/index.vue";
import { form } from "@/mixins/form.js";
import * as Formx from "@/api/form/data";
export default {
    mixins: [form],
    name: "FormTable",
    components: {
        VFormRender,
    },
    props: {
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            treeConfig: {
                trigger: "row",
                expandRowKeys: [],
            },
            org_optionItems: {},

            queryformData: null, //查询表单数据
            queryformDataorgstr: "",
            formData: null, //表单数据
            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },

            //图片预览地址
            dialogImageUrl: "",
            //图片预览对话框
            dialogVisible: false,

            loadingstu: false,
            openselstu: false,

            // 工具栏
            tableToolbar: {
                perfect: false,
                zoom: true,
                custom: false,
                refresh: {
                    query: this.handleQuery,
                },
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            // 数据列
            tableColumn: [
                // {
                // 	type: "checkbox",
                // 	width: 60,
                // 	fixed: "left"
                // },
                {
                    field: "id",
                    title: "ID",
                    width: 100,
                    fixed: "left",
                },

                // {
                // 	field: "",
                // 	title: "操作",
                // 	resizable: false,
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: "defaultopr"
                // 	},
                // },
            ],

            orgcolumnList: [],
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 总条数
            total: 0,
            totalstu: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 快速检索数据
            Quitfilter_data: {},
            // 快速检索数据 字典类型
            Quitfilter_keys: [],
            // 是否显示弹出层
            open: false,
            // 机构名称
            deptname: undefined,

            //表单
            form: {},
            defaultProps: {
                children: "children",
                label: "name",
            },

            // 表单校验
            rules: {},
            userInfo: {},
            typeOptions: [],
        };
    },

    created() {
        // console.log("this.qbid",this.qbid)
        this.$nextTick(() => {
            if (!customFields || !customFields.length) {
                loadExtension();
            }
        });
    },
    computed: {
        columnSlots: function () {
            var slots = this.tableColumn.filter((item) => {
                return item.slots;
            });
            return slots;
            // console.log(this.tableColumn.filter((item) => {return item.slots}))
        },
    },
    watch: {
        queryformData: {
            handler(val) {
                // console.log(val, "测试");
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        init(mid) {
            console.log('进入formtable')
            // this.show = true;
            this.mid = mid;
            //初始化默认条件
            this.initdeffilter();

            this.queryParams.sortinfo = this.defsort;
            this.getforminfo(this.mid,'',[],false);
            //
            //console.log(this.config, "init config");
            setTimeout(() => {
                this.initdeffilter();
            }, 2000);
        },
        initdeffilter() {
            if (
                this.config["filters"] != null &&
                Array.isArray(this.config["filters"]) &&
                this.config["filters"].length > 0
            ) {
                for (let k in this.config["filters"]) {
                    let oneflt = this.config["filters"][k];
                    if (oneflt.type == "=") {
                        this.queryParams.querytype[oneflt.field] = "=";
                    } else {
                        this.queryParams.querytype[oneflt.field] = "like";
                    }

                    this.queryParams.fieldval[oneflt.field] = oneflt.value;
                }
            }
        },
        Oncelldblclick(row) {
            // this.handleUpdate(row.row, "查看");
        },
        checkboxChangeEvent({ records }) {
            this.$emit("change", records);
        },
        getFun(item, row) {
            return this[item.slotsFun.funName](
                item.slotsFun.dt,
                row[item.slotsFun.key],
                item.slotsFun.key
            );
        },
        /** 查询数据 */
        getList(mid) {
            this.loading = true;
            if (this.propsqueryparam != null) {
                // console.log("this.propsqueryparam",this.propsqueryparam);
                for (let field in this.propsqueryparam) {
                    let v = this.propsqueryparam[field];
                    this.queryParams.fieldval[field] = v;
                }
            }

            Formx.FormxShowList(mid, this.queryParams)
                .then((response) => {
                    this.loading = false;
                    console.log(" response.data", response.data);
                    //return false;

                    if (this.treeConfig.parentField != null) {
                        var tlist = [];
                        for (let k in response.data.list) {
                            let tonef = response.data.list[k];
                            tonef["label"] = tonef[this.treeConfig.treeNode];

                            tlist.push(JSON.parse(JSON.stringify(tonef)));
                        }
                        try {
                            this.dataList = this.handleTree(
                                tlist,
                                "id",
                                this.treeConfig.parentField
                            );
                        } catch (error) {
                            console.log(" this.dataList error", error);
                        }

                        console.log(" this.dataList", this.dataList);
                    } else {
                        setTimeout(() => {
                            this.dataList = response.data.list;
                        }, 150);
                    }
                    console.log(this.dataList, "   this.dataList");
                    this.total = response.data.total;
                })
                .catch(() => {
                    this.loading = false;
                    // this.msgError("加载数据出错,请先检查表单配置!");
                });
        },
    },
};
</script>